export interface props {
    title: string,
    subTitle?: string,
    cells: string[],
    tagText?: string,
    onClick?: () => void
    className?: string
}

export const ListItem = (props: props) => {
    return (
        <div className={`flex flex-row justify-between hover:bg-elevation-2 mb-2 rounded-sm bg-elevation-1 h-16 border-solid border-l-[5px] border-solid-bold-green cursor-pointer ${props.className}`} onClick={props.onClick}>
            <div className=" pl-4 mt-2">
                <div>
                    <span className="font-bold jbp-list-item-title">{props.title}</span>
                </div>
                <div>
                    <span className="text-sm text-soft-elevation-0 jbp-list-item-subtitle">{props.subTitle == undefined ? "" : props.subTitle}</span>
                </div>
            </div>
            <div className="mt-6 mr-6">
                <img src="/externalLinkButton.svg" alt="External Link" className="h-5" />
            </div>
        </div>
    )
}
